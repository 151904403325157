.no-margin {
    margin: 0;
}
.text {
    width: 90%;
    text-align: justify;
    font-size: 14px;
}

.title-form {
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1.5rem;
}
form label {
    display: inherit !important;
    margin-bottom: 1.2rem;
}
input {
    width: 100%;
    text-align: left;
    border-radius: 0.3rem !important;
    padding: 8px 12px;
    border: 0.5px solid lightgray;
}
button {
    width: 100%;
    text-align: center;
    border-radius: 0.3rem !important;
    padding: 8px 12px;
    background-color: #0094FF;
    font-weight: 600;
    color: white;
    border: none;
}

.password-utils {
    width: 100%;
    margin: 1rem 0;

}
form span {
    font-size:13px;
}
.toast {    
    position: absolute !important;
    top:20vh;
    right:10vw;
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-size: 2rem;
}
.pass-wrapper {
    position: relative;
    display: flex;
    margin-bottom: 14px;
  }
  
  i {
    position: absolute;
    top: 50%;
    right: 3%;
  }
  i:hover {
    color: #005999;
    cursor: pointer;
  }
  