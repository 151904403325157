select {
    width: 100%;
    text-align: left;
    border-radius: 0.3rem !important;
    padding: 8px 12px;
    border: 0.5px solid lightgray;
}
.container-comp {
    height: 100vh;
    background-image: url('../../assets/images/Imagen1.png');
    background-size: cover;
}

.form-container {
  background-color: white;
  border-radius: 1rem;
  padding: 1rem 0;
  min-height: 70vh;
  height: auto;
  box-shadow: 0 10px 26px 0px rgba(0,0,0,0.59);
-webkit-box-shadow: 0 10px 26px 0px rgba(0,0,0,0.59);
-moz-box-shadow: 0 10px 26px 0px rgba(0,0,0,0.59);
}

/* HIDE RADIO */
[type=radio] { 
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* IMAGE STYLES */
  [type=radio] + img {
    cursor: pointer;
  }
  
  /* CHECKED STYLES */
  [type=radio]:checked + img {
    outline: 2px solid #0e89e0;
  }

  .btn {
    padding: 8px 12px;
    border-radius: 0.2rem;
    background-color: #0e89e0;
  }

  /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
  margin-right: 1rem;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}