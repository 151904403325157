.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul {
    height: 88vh;
    display:flex;
    flex-direction: column;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul .pro-menu-item:last-child {
    margin-top: 11vh;
}
.pro-sidebar {
    color: #1e1e1e !important;
}
.pro-sidebar > .pro-sidebar-inner {
    background: white;
    border-right: 1px solid lightgray;
}

.pro-sidebar .pro-menu.square .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background: #0094FF;
    color: white;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    font-size: 18px;
  }
.pro-sidebar .pro-menu a {
    color: #1e1e1e;
}
  
.pro-sidebar .pro-menu {
    padding-top: 0;
    padding-bottom: 0;
}

.pro-sidebar-header {
    padding-left: 10px;
    height: 58px!important;
   
}
ul li:hover a {
    color: #334D6E !important;
    font-weight: bold;
}
.pro-sidebar .pro-menu .pro-menu-item.active div{
    color: #d8d8d8;
    font-weight: bold;
    background-color: #dfedff;
    margin: 0 8px;
}
.logoutIcon {
    position: absolute;
    bottom: 2.5vh;
}