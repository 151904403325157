#header {
  height: 100vh;
  background-color: #E5E5E5;
  overflow: hidden;
}
.navbar {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  border-bottom: 1px solid lightgray;

}
.navbar-expand-md {
  padding: 0 10px;
  justify-content: space-between !important;
}  
#navbar .closemenu {
  color: rgb(56, 56, 56);
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 47px;
  cursor: pointer;
}
#navbar .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}
#navbar .pro-sidebar-inner {
  background-color: white;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}
#navbar .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}
#navbar .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 36px;
  padding: 2px 20px;
  color: #0094FF;
  font-weight: bold;
}
#navbar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
  color: #000;
}

#navbar .logo {
  padding: 20px;
}
.navbar-brand {
  z-index: 999;
  font-weight: 500;
  font-size: 1.8rem !important;
}
.notif {
  margin-right: 1rem;
}
.profile-data {
  margin-right: 1rem;
  text-align: right;
}
.profile-picture {
  z-index: 999;
  border-radius: 50%;
  width: 45px;
  height: 45px; 
  background-color: #F7685B; 
  text-align: center;
  color: white;
  font-weight: 600;
}
a {
  text-decoration: none !important;
}
.underlined-text {
  font-size: 12px;
}
.dot {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  position: absolute;
  background-color: #F7685B;
  margin: 0 0 1rem 1.1rem;
}

.cover {
  object-fit: cover;
}